<template>
  <div class="container-403">
    <h1>
      4
      <div class="lock">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      3
    </h1>
    <p>Access denied</p>
    <router-link to="/home">
      <p>Back page</p>
    </router-link>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Comfortaa");

.container-403 {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  min-width: 300px;
  div {
    display: inline-block;
  }
  .lock {
    opacity: 1;
  }
  h1 {
    font-family: "Comfortaa", cursive;
    font-size: 100px;
    text-align: center;

    font-weight: 100;
    margin: 0;
  }
  p {
  }
}
.lock {
  transition: 0.5s ease;
  position: relative;
  overflow: hidden;
  opacity: 0;
  &.generated {
    transform: scale(0.5);
    position: absolute;
    animation: 2s move linear;
    animation-fill-mode: forwards;
  }
  ::after {
    content: "";
    background: darken(#f76b1c, 20%);
    opacity: 0.3;
    display: block;
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 0;
  }
  .bottom {
    background: #d68910;
    height: 40px;
    width: 60px;
    display: block;
    position: relative;
    margin: 0 auto;
  }
  .top {
    height: 60px;
    width: 50px;
    border-radius: 50%;
    border: 10px solid #fff;
    display: block;
    position: relative;
    top: 30px;
    margin: 0 auto;
    &::after {
      padding: 10px;
      border-radius: 50%;
    }
  }
}
@keyframes move {
  to {
    top: 100%;
  }
}
@media (max-width: 420px) {
  .container {
    transform: translate(-50%, -50%) scale(0.8);
  }
  .lock.generated {
    transform: scale(0.3);
  }
}
</style>
